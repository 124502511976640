require('./bootstrap');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'Accept': 'application/json',
    }
});

var datatableColumns = [];

$('.datatable th').each(function () {
    var column = $(this).data('column');
    datatableColumns.push({
        data: column,
        name: column,
        orderable: true,
    });
});

datatable = $('.datatable').DataTable({
    processing: true,
    serverSide: true,
    ajax: {
        "url": $('.datatable').data('url'),
        "type": "GET",
        'headers': {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: function (d) {
            // $(".loading").LoadingOverlay("show");
            // $(".loading").LoadingOverlay("hide", true);

            var datatableFilters = [];

            $.each($(".datatable").data("filters"), function (index, val) {
                console.log($('#' + val).val());
                datatableFilters[val] = $('#' + val).val()
            })

            return $.extend({}, d, datatableFilters);
        }
    },
    language: {
        "url": "/admin/tr.json"
    },
    columns: datatableColumns,
    order: [[0, "desc"]],
    searching: false
});

$('#filter').on('click touchstart', function (e) {
    e.preventDefault();
    datatable.draw()
});

window.ajaxService = function ajaxService(ajaxUrl, postParam, type, callback = false, async = true) {
    $.ajax({
        url: ajaxUrl,
        data: postParam,
        dataType: 'json',
        async: async,
        type: type,
        success: function (response) {
            if (callback) {
                callback(response);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            $.each(xhr.responseJSON.errors, function (index, val) {
                Toast.create("Bir hata oluştu", val, TOAST_STATUS.DANGER)
            });
            callback(null, thrownError);
        },
    });
}


